export const ACTION_TYPE = {
    up: 'w',
    down: 's',
    left: 'a',
    right: 'd',
    a: 'j',
    b: 'k',
    select: 'space',
    start: 'enter',
};
export const ICON_UP = '<svg t="1602585885905" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3315" width="64" height="64"><path d="M472.625 277.62499971L82.62500029 667.62500029c-35.62499971 35.62499971-11.25 95.625 39.375 95.625h778.12499971c50.625 0 74.99999971-60.00000029 39.375-95.625L551.375 277.62499971c-22.5-22.5-56.25-22.5-78.75 0z" p-id="3316" fill="#1890ff"></path></svg>';
export const ICON_DOWN = '<svg t="1602585846125" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3083" width="64" height="64"><path d="M936.8 374.00000029L557 789.31999971a60.00000029 60.00000029 0 0 1-89.75999971 0L87.31999971 374.00000029a70.44000029 70.44000029 0 0 1 44.88000029-118.56000058h759.35999971A70.44000029 70.44000029 0 0 1 936.8 374.00000029z m0 0" p-id="3084" fill="#1890ff"></path></svg>';
export const ICON_LEFT = '<svg t="1602585943535" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2538" width="64" height="64"><path d="M659.303637 102.27982143L273.63593249 469.19979018c-12.05211579 10.71299163-18.74773577 26.78247908-18.74773494 42.85196735s6.69561998 32.13897573 18.74773492 42.85196653L659.303637 921.8236928c18.74773577 17.40861161 44.19109151 22.76510742 68.29532309 14.73036412 22.76510742-9.37386747 40.17371903-32.13897573 41.51284236-57.58233147V146.4709121C766.43355497 121.0275572 750.36406751 98.26244978 727.59896009 88.88858148c-24.10423159-10.71299163-50.88671065-5.35649581-68.29532309 13.39123995z" p-id="2539" fill="#1890ff"></path></svg>';
export const ICON_RIGHT = '<svg t="1602585791326" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2851" width="64" height="64"><path d="M393.10162945 921.88606578L788.99920441 554.4252325c29.32393529-27.21306851 29.32393529-71.33985109 0-98.56456249l-395.89874005-367.46083328c-47.29832221-43.9090597-128.17258496-12.81423133-128.17258496 49.27645924L264.92788054 872.60960654C264.92904449 934.7002971 345.80330724 965.79396039 393.10162945 921.88606578z" p-id="2852" fill="#1890ff"></path></svg>';

