<template>
    <div class="v-television">
        <div class="tv-container">
            <Spin :spinning="isLoading">
                <div class="tv-screen">
                    <slot></slot>
                </div>
            </Spin>
            <div class="tv-control">
                <div class="control-item"></div>
                <div class="control-item"></div>
                <div class="control-item"></div>
                <div class="control-item"></div>
                <div class="control-switch"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Spin } from 'ant-design-vue';
export default {
    name: 'Television', // 电视
    components: {
        Spin,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="less">
.v-television {
    width: 316px;
    height: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .tv-container {
        border: 6px solid #FF6601;
        border-radius: 10px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #505557;
        .tv-screen {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 3px 2px #a8abae inset, 0 -3px 2px #a8abae inset;
            width: 256px;
            height: 240px;
            background-color: #ccc;
        }
        .tv-control {
            width: 100%;
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: flex-end;
            .control-item {
                width: 10px;
                height: 10px;
                border-radius: 6px;
                margin-right: 10px;
                box-shadow: 0 1px 2px #fff;
                background: -webkit-linear-gradient(top,#f2f3f7,#e4e8ec);
            }
            .control-switch {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                margin-right: 23px;
                box-shadow: 0 1px 2px #fff inset, 0 -1px 0 #a8abae inset;
                background: -webkit-linear-gradient(top,#f2f3f7,#e4e8ec);
            }
        }
    }
}
</style>
