// 屏幕宽度
export const SCREEN_WIDTH = 256;
// 屏幕高度
export const SCREEN_HEIGHT = 240;
// 屏幕大小
export const FRAMEBUFFER_SIZE = SCREEN_WIDTH * SCREEN_HEIGHT;
// 音频buffer长度
export const AUDIO_BUFFERING = 512;
export const SAMPLE_COUNT = 4 * 1024;
export const SAMPLE_MASK = SAMPLE_COUNT - 1;


