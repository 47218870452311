<template>
    <div class="v-game-card">
        <img class="game-cover" :src="info.cover" alt="">
        <div class="game-name">{{info.name}}</div>
    </div>
</template>

<script>
export default {
    name: 'GameCard', // 游戏卡
    props: {
        info: Object,
    },
};
</script>

<style lang="less">
.v-game-card {
    width: 150px;
    height: 180px;
    margin-bottom: 20px;
    .game-cover {
        width: 150px;
        height: 150px;
        border-radius: 6px;
    }
    .game-name {
        height: 30px;
        width: 100%;
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
    }
}
</style>
