import jsnes from 'jsnes';
import keycode from 'keycode';
import { SCREEN_WIDTH, SCREEN_HEIGHT, FRAMEBUFFER_SIZE, AUDIO_BUFFERING, SAMPLE_COUNT, SAMPLE_MASK } from '../constants/screen';
import { ACTION_TYPE } from '../constants/action';
import NESCntlr from 'nes-cntlr';

let canvas_ctx, image;
let animationId;
let framebuffer_u8, framebuffer_u32;
let audio_samples_L = new Float32Array(SAMPLE_COUNT);
let audio_samples_R = new Float32Array(SAMPLE_COUNT);
let audio_write_cursor = 0, audio_read_cursor = 0;

const NES = new jsnes.NES({
    onFrame: function(framebuffer_24){
        for (let i = 0; i < FRAMEBUFFER_SIZE; i++) {
            framebuffer_u32[i] = 0xFF000000 | framebuffer_24[i];
        }
    },
    onAudioSample: function(l, r){
        audio_samples_L[audio_write_cursor] = l;
        audio_samples_R[audio_write_cursor] = r;
        audio_write_cursor = (audio_write_cursor + 1) & SAMPLE_MASK;
    },
});
const NES_CONTROL = jsnes.Controller;

const audioRemain = () => {
    return (audio_write_cursor - audio_read_cursor) & SAMPLE_MASK;
};

const audioCallback = (event) => {
    const DST = event.outputBuffer;
    const len = DST.length;
    if (audioRemain() < AUDIO_BUFFERING) NES.frame();
    const DST_Left = DST.getChannelData(0);
    const DST_Right = DST.getChannelData(1);
    for (let i = 0; i < len; i++) {
        let idx = (audio_read_cursor + i) & SAMPLE_MASK;
        DST_Left[i] = audio_samples_L[idx];
        DST_Right[i] = audio_samples_R[idx];
    }
    audio_read_cursor = (audio_read_cursor + len) & SAMPLE_MASK;
};

// 创建浏览器帧动画
const onAnimationFrame = () => {
    animationId && window.cancelAnimationFrame(animationId);
    animationId = window.requestAnimationFrame(onAnimationFrame);
    image.data.set(framebuffer_u8);
    canvas_ctx.putImageData(image, 0, 0);
};

export const initNes = (canvasElement) => {
    canvas_ctx = canvasElement.getContext('2d');
    image = canvas_ctx.getImageData(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);

    canvas_ctx.fillStyle = 'black';
    canvas_ctx.fillRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);

    // 分配 buffer 数据
    var buffer = new ArrayBuffer(image.data.length);
    framebuffer_u8 = new Uint8ClampedArray(buffer);
    framebuffer_u32 = new Uint32Array(buffer);
};

// 加载rom
export const bootNes = (romData) => {
    NES.loadROM(romData);
    window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
    // 设置音频
    var audio_ctx = new window.AudioContext();
    var script_processor = audio_ctx.createScriptProcessor(AUDIO_BUFFERING, 0, 2);
    script_processor.onaudioprocess = audioCallback;
    script_processor.connect(audio_ctx.destination);
    animationId && window.cancelAnimationFrame(animationId);
    // 开启动画
    animationId = window.requestAnimationFrame(onAnimationFrame);
};


export const bindAction = () => {
    let player1 = new NESCntlr({
        virtual: 'never',
        // keys: {
        //     start: keycode(ACTION_TYPE.start),
        //     select: keycode(ACTION_TYPE.select),
        //     left: keycode(ACTION_TYPE.left),
        //     up: keycode(ACTION_TYPE.up),
        //     right: keycode(ACTION_TYPE.right),
        //     down: keycode(ACTION_TYPE.down),
        //     b: keycode(ACTION_TYPE.b),
        //     a: keycode(ACTION_TYPE.a),
        // },
    });
    player1.init();
    // document.addEventListener('player1:up-left', (event) => {
    //     const { pressed } = event.detail;
    //     // virtualKeyboard(NES.buttonDown, ACTION_TYPE.up);
    //     fireKeyEvent(document.querySelector('.action-demo'), pressed ? 'keydown' : 'keyup', keycode(ACTION_TYPE.up));
    //     fireKeyEvent(document.querySelector('.action-demo'), pressed ? 'keydown' : 'keyup', keycode(ACTION_TYPE.left));
    //     event.preventDefault();
    // });
    document.addEventListener('player1:up', (event) => {
        const { pressed } = event.detail;
        // console.log('virtualKeyboard => up', event, event.detail);
        // virtualKeyboard(NES.buttonDown, ACTION_TYPE.up);
        // fireKeyEvent(document.querySelector('.action-demo'), pressed ? 'keydown' : 'keyup', keycode(ACTION_TYPE.up));
        if (pressed) {
            fireKeyEvent(document.querySelector('.action-demo'), 'keydown', keycode(ACTION_TYPE.up));
        } else {
            fireKeyEvent(document.querySelector('.action-demo'), 'keyup', keycode(ACTION_TYPE.up));
        }
        event.preventDefault();
    });
    // document.addEventListener('player1:up-left player1:up player1:up-right player1:right player1:left player1:down-left player1:down player1:down-right player1:b player1:a player1:select player1:start', (event) => {
    //     console.log('virtualKeyboard => up', event, event.detail);
    //     const { pressed } = event.detail;
    //     // virtualKeyboard(NES.buttonDown, ACTION_TYPE.up);
    //     fireKeyEvent(document.querySelector('.action-demo'), 'keydown', keycode(ACTION_TYPE.up));
    // });
    // document.addEventListener('player1:left', () => {
    //     console.log('virtualKeyboard => left');
    //     // virtualKeyboard(NES.buttonDown, ACTION_TYPE.up);
    //     fireKeyEvent(document.querySelector('.action-demo'), 'keydown', keycode(ACTION_TYPE.left));
    // });
    // document.addEventListener('player1:select', () => {
    //     console.log('virtualKeyboard => select');
    //     virtualKeyboard(NES.buttonDown, ACTION_TYPE.select);
    // });
    document.addEventListener('keydown', (event) => {
        keyboard(NES.buttonDown, event);
    });
    document.addEventListener('keyup', (event) => {
        keyboard(NES.buttonUp, event);
    });
};

export const bindVirtualAction = (selector, type) => {
    // console.log('bindVirtualAction');
    const element = document.querySelector(selector);
    element.addEventListener('touchstart', (event) => {
        element.classList.add('active');
        fireKeyEvent(document.querySelector('.action-demo'), 'keydown', keycode(type));
        event.preventDefault();
    }, false);
    element.addEventListener('touchend', (event) => {
        element.classList.remove('active');
        fireKeyEvent(document.querySelector('.action-demo'), 'keyup', keycode(type));
        event.preventDefault();
    }, false);
};

// const virtualKeyboard = (callback, type) => {
//     var player = 1;
//     console.log('输入的值为：虚拟事件', type);
//     switch(type){
//     case ACTION_TYPE.up: // UP
//         callback(player, jsnes.Controller.BUTTON_UP); break;
//     case ACTION_TYPE.down: // Down
//         callback(player, jsnes.Controller.BUTTON_DOWN); break;
//     case ACTION_TYPE.left: // Left
//         callback(player, jsnes.Controller.BUTTON_LEFT); break;
//     case ACTION_TYPE.right: // Right
//         callback(player, jsnes.Controller.BUTTON_RIGHT); break;
//     case ACTION_TYPE.a: // A
//         callback(player, jsnes.Controller.BUTTON_A); break;
//     case ACTION_TYPE.b: // B
//         callback(player, jsnes.Controller.BUTTON_B); break;
//     case ACTION_TYPE.select: // Select
//         callback(player, jsnes.Controller.BUTTON_SELECT); break;
//     case ACTION_TYPE.start: // Start
//         callback(player, jsnes.Controller.BUTTON_START); break;
//     default: break;
//     }
// };

const keyboard = (callback, event) => {
    var player = 1;
    // console.log('输入的值为：', keycode(event.keyCode));
    switch(keycode(event.keyCode)){
    case ACTION_TYPE.up: // UP
        callback(player, jsnes.Controller.BUTTON_UP); break;
    case ACTION_TYPE.down: // Down
        callback(player, jsnes.Controller.BUTTON_DOWN); break;
    case ACTION_TYPE.left: // Left
        callback(player, jsnes.Controller.BUTTON_LEFT); break;
    case ACTION_TYPE.right: // Right
        callback(player, jsnes.Controller.BUTTON_RIGHT); break;
    case ACTION_TYPE.a: // A
        callback(player, jsnes.Controller.BUTTON_A); break;
    case ACTION_TYPE.b: // B
        callback(player, jsnes.Controller.BUTTON_B); break;
    case ACTION_TYPE.select: // Select
        callback(player, jsnes.Controller.BUTTON_SELECT); break;
    case ACTION_TYPE.start: // Start
        callback(player, jsnes.Controller.BUTTON_START); break;
    default: break;
    }
};

// 模拟键盘事件
export const fireKeyEvent = (el, evtType, keyCode) => {
    var evtObj;
    if (document.createEvent) {
        if (window.KeyEvent) {//firefox 浏览器下模拟事件
            evtObj = document.createEvent('KeyEvents');
            evtObj.initKeyEvent(evtType, true, true, window, true, false, false, false, keyCode, 0);
        } else {//chrome 浏览器下模拟事件
            evtObj = document.createEvent('UIEvents');
            evtObj.initUIEvent(evtType, true, true, window, 1);

            delete evtObj.keyCode;
            if (typeof evtObj.keyCode === 'undefined') {//为了模拟keycode
                Object.defineProperty(evtObj, 'keyCode', { value: keyCode });
            } else {
                evtObj.key = String.fromCharCode(keyCode);
            }

            if (typeof evtObj.ctrlKey === 'undefined') {//为了模拟ctrl键
                Object.defineProperty(evtObj, 'ctrlKey', { value: true });
            } else {
                evtObj.ctrlKey = true;
            }
        }
        el.dispatchEvent(evtObj);

    } else if (document.createEventObject) {//IE 浏览器下模拟事件
        evtObj = document.createEventObject();
        evtObj.keyCode = keyCode;
        el.fireEvent('on' + evtType, evtObj);
    }
};


export const getNesData = (url) => {
    return new Promise((reslove, reject) => {
        let req = new XMLHttpRequest();
        req.open('GET', url);
        req.overrideMimeType('text/plain; charset=x-user-defined');
        req.onerror = () => {
            reslove(false);
        };
        req.onload = function() {
            if (this.status === 200) {
                reslove(this.responseText);
            } else if (this.status === 0) {
                reslove(false);
            } else {
                reslove(false);
            }
        };
        req.send();
    });
};
