<template>
    <Drawer
        placement="bottom"
        height="85%"
        :closable="false"
        :visible="visible"
        wrapClassName="v-choose-game"
    >
        <div class="header-title">
            选择游戏
            <img class="close-action" @click="close" src="../assets/close.png" alt="">
        </div>
        <div class="game-list">
            <Row :gutter="20">
                <Col :span="12" v-for="(item, index) in list" :key="index" @click="() => choose(item)">
                    <GameCard :info="item" />
                </Col>
            </Row>
        </div>
    </Drawer>
</template>

<script>
import { Drawer, Row, Col } from 'ant-design-vue';
import GameCard from './GameCard';
import { ROMS_LIST, ROMS_LIST_DATA } from '../constants/roms';
export default {
    name: 'ChooseGame', // 选择
    components: {
        Drawer,
        Row,
        Col,
        GameCard,
    },
    data() {
        return {
            list: ROMS_LIST_DATA,
            visible: false,
        };
    },
    methods: {
        open() {
            this.visible = true;
        },
        close() {
            this.visible = false;
        },
        choose(item) {
            this.close();
            this.$emit('ensure', item);
        },
    },
};
</script>

<style lang="less">
@border-radius: 20px;
.v-choose-game {
    .ant-drawer-content-wrapper,
    .ant-drawer-content {
        border-top-left-radius: @border-radius;
        border-top-right-radius: @border-radius;
    }
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    .header-title {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .close-action {
            position: absolute;
            right: @border-radius;
            top: 50%;
            height: 16px;
            width: 16px;
            margin-top: -8px;
        }
    }
    .game-list {
        flex: 1;
        overflow-y: auto;
        padding: 0 20px;
        .game-item {
            margin-bottom: 20px;
            border-radius: @border-radius / 2;
            font-weight: bold;
            text-align: center;
        }
    }
}
</style>
