export const ROMS_LIST = [
    { name: '俄罗斯方块', link: 'http://image.uiseed.cn/lj65.nes' },
    { name: '1943', link: 'http://image.uiseed.cn/1943.nes' },
    { name: 'AV麻将', link: 'http://image.uiseed.cn/AV麻将.nes' },
    { name: 'F1赛车', link: 'http://image.uiseed.cn/F1赛车.nes' },
    { name: 'FC原人', link: 'http://image.uiseed.cn/FC原人.nes' },
    { name: '七宝奇谋', link: 'http://image.uiseed.cn/七宝奇谋.nes' },
    { name: '三只小猪', link: 'http://image.uiseed.cn/三只小猪.nes' },
    { name: '三目童子', link: 'http://image.uiseed.cn/三目童子.nes' },
    { name: '中东战争', link: 'http://image.uiseed.cn/中东战争.nes' },
    { name: '中华大仙', link: 'http://image.uiseed.cn/中华大仙.nes' },
    { name: '中国象棋', link: 'http://image.uiseed.cn/中国象棋.nes' },
    { name: '人间兵器', link: 'http://image.uiseed.cn/人间兵器.nes' },
    { name: '企鹅梦物语', link: 'http://image.uiseed.cn/企鹅梦物语.nes' },
    { name: '俄罗斯方块', link: 'http://image.uiseed.cn/俄罗斯方块.nes' },
    { name: '充气狗', link: 'http://image.uiseed.cn/充气狗.nes' },
    { name: '兵蜂', link: 'http://image.uiseed.cn/兵蜂.nes' },
    { name: '冒险岛', link: 'http://image.uiseed.cn/冒险岛.nes' },
    { name: '冒险岛4', link: 'http://image.uiseed.cn/冒险岛4.nes' },
    { name: '功夫', link: 'http://image.uiseed.cn/功夫.nes' },
    { name: '加纳战机', link: 'http://image.uiseed.cn/加纳战机.nes' },
    { name: '南京企鹅', link: 'http://image.uiseed.cn/南京企鹅.nes' },
    { name: '双截龙2', link: 'http://image.uiseed.cn/双截龙2.nes' },
    { name: '双截龙3', link: 'http://image.uiseed.cn/双截龙3.nes' },
    { name: '古巴英雄', link: 'http://image.uiseed.cn/古巴英雄.nes' },
    { name: '吃豆', link: 'http://image.uiseed.cn/吃豆.nes' },
    { name: '唐老鸭梦冒险', link: 'http://image.uiseed.cn/唐老鸭梦冒险.nes' },
    { name: '唐老鸭梦冒险2', link: 'http://image.uiseed.cn/唐老鸭梦冒险2.nes' },
    { name: '嘉蒂外传', link: 'http://image.uiseed.cn/嘉蒂外传.nes' },
    { name: '地底探险', link: 'http://image.uiseed.cn/地底探险.nes' },
    { name: '坦克大战', link: 'http://image.uiseed.cn/坦克大战.nes' },
    { name: '外星战将', link: 'http://image.uiseed.cn/外星战将.nes' },
    { name: '小蜜蜂', link: 'http://image.uiseed.cn/小蜜蜂.nes' },
    { name: '彩虹岛', link: 'http://image.uiseed.cn/彩虹岛.nes' },
    { name: '影子传说', link: 'http://image.uiseed.cn/影子传说.nes' },
    { name: '忍者神龟2', link: 'http://image.uiseed.cn/忍者神龟2.nes' },
    { name: '忍者神龟3', link: 'http://image.uiseed.cn/忍者神龟3.nes' },
    { name: '忍者蛙', link: 'http://image.uiseed.cn/忍者蛙.nes' },
    { name: '忍者龙剑传1', link: 'http://image.uiseed.cn/忍者龙剑传1.nes' },
    { name: '忍者龙剑传2', link: 'http://image.uiseed.cn/忍者龙剑传2.nes' },
    { name: '忍者龙剑传3', link: 'http://image.uiseed.cn/忍者龙剑传3.nes' },
    { name: '快乐猫', link: 'http://image.uiseed.cn/快乐猫.nes' },
    { name: '快打旋风', link: 'http://image.uiseed.cn/快打旋风.nes' },
    { name: '怪鸭历险', link: 'http://image.uiseed.cn/怪鸭历险.nes' },
    { name: '恶魔城', link: 'http://image.uiseed.cn/恶魔城.nes' },
    { name: '成龙之龙', link: 'http://image.uiseed.cn/成龙之龙.nes' },
    { name: '成龙踢馆', link: 'http://image.uiseed.cn/成龙踢馆.nes' },
    { name: '打砖块', link: 'http://image.uiseed.cn/打砖块.nes' },
    { name: '拆屋工', link: 'http://image.uiseed.cn/拆屋工.nes' },
    { name: '摩托车大赛', link: 'http://image.uiseed.cn/摩托车大赛.nes' },
    { name: '敲冰块', link: 'http://image.uiseed.cn/敲冰块.nes' },
    { name: '最终任务', link: 'http://image.uiseed.cn/最终任务.nes' },
    { name: '未来战士', link: 'http://image.uiseed.cn/未来战士.nes' },
    { name: '松鼠1', link: 'http://image.uiseed.cn/松鼠1.nes' },
    { name: '松鼠2', link: 'http://image.uiseed.cn/松鼠2.nes' },
    { name: '柯拉米世界2', link: 'http://image.uiseed.cn/柯拉米世界2.nes' },
    { name: '桌球', link: 'http://image.uiseed.cn/桌球.nes' },
    { name: '沙罗曼蛇', link: 'http://image.uiseed.cn/沙罗曼蛇.nes' },
    { name: '洛克人', link: 'http://image.uiseed.cn/洛克人.nes' },
    { name: '激龟快打', link: 'http://image.uiseed.cn/激龟快打.nes' },
    { name: '火之鸟', link: 'http://image.uiseed.cn/火之鸟.nes' },
    { name: '火箭车', link: 'http://image.uiseed.cn/火箭车.nes' },
    { name: '炸弹人', link: 'http://image.uiseed.cn/炸弹人.nes' },
    { name: '热血格斗传说', link: 'http://image.uiseed.cn/热血格斗传说.nes' },
    { name: '特救指令', link: 'http://image.uiseed.cn/特救指令.nes' },
    { name: '王子外传', link: 'http://image.uiseed.cn/王子外传.nes' },
    { name: '究极虎', link: 'http://image.uiseed.cn/究极虎.nes' },
    { name: '绿色兵团', link: 'http://image.uiseed.cn/绿色兵团.nes' },
    { name: '脱狱', link: 'http://image.uiseed.cn/脱狱.nes' },
    { name: '脱狱2', link: 'http://image.uiseed.cn/脱狱2.nes' },
    { name: '荒野大镖客', link: 'http://image.uiseed.cn/荒野大镖客.nes' },
    { name: '菲力猫', link: 'http://image.uiseed.cn/菲力猫.nes' },
    { name: '蝙蝠侠', link: 'http://image.uiseed.cn/蝙蝠侠.nes' },
    { name: '西游记', link: 'http://image.uiseed.cn/西游记.nes' },
    { name: '赤影战士', link: 'http://image.uiseed.cn/赤影战士.nes' },
    { name: '赤色要塞', link: 'http://image.uiseed.cn/赤色要塞.nes' },
    { name: '超级玛莉', link: 'http://image.uiseed.cn/超级玛莉.nes' },
    { name: '超级魂斗罗', link: 'http://image.uiseed.cn/超级魂斗罗.nes' },
    { name: '越野机车', link: 'http://image.uiseed.cn/越野机车.nes' },
    { name: '踢王', link: 'http://image.uiseed.cn/踢王.nes' },
    { name: '顽皮狗', link: 'http://image.uiseed.cn/顽皮狗.nes' },
    { name: '马戏团', link: 'http://image.uiseed.cn/马戏团.nes' },
    { name: '魂斗罗', link: 'http://image.uiseed.cn/魂斗罗.nes' },
    { name: '魔鬼总动员', link: 'http://image.uiseed.cn/魔鬼总动员.nes' },
    { name: '鳄鱼先生', link: 'http://image.uiseed.cn/鳄鱼先生.nes' },
    { name: '鸟人战队', link: 'http://image.uiseed.cn/鸟人战队.nes' }
];

import {
    CHAOJIHUNDOULUO,
    CHAOJIMALI,
    GONGFU,
    KUAIDAXUANFENG,
    MAOXIANDAO,
    MAXITUAN,
    SANMUTONGZI,
    XIYOUJI,
} from './cover';

export const ROMS_LIST_DATA = [
    { name: '快打旋风', link: 'https://oss.uiseed.cn/game/快打旋风.nes', cover: KUAIDAXUANFENG },
    { name: '三目童子', link: 'https://oss.uiseed.cn/game/三目童子.nes', cover: SANMUTONGZI },
    { name: '西游记', link: 'https://oss.uiseed.cn/game/西游记.nes', cover: XIYOUJI },
    { name: '冒险岛', link: 'https://oss.uiseed.cn/game/冒险岛.nes', cover: MAOXIANDAO },
    { name: '功夫', link: 'https://oss.uiseed.cn/game/功夫.nes', cover: GONGFU },
    { name: '超级玛莉', link: 'https://oss.uiseed.cn/game/超级玛莉.nes', cover: CHAOJIMALI },
    { name: '超级魂斗罗', link: 'https://oss.uiseed.cn/game/超级魂斗罗.nes', cover: CHAOJIHUNDOULUO },
    { name: '马戏团', link: 'https://oss.uiseed.cn/game/马戏团.nes', cover: MAXITUAN },
];
