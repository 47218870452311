import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';
import MainGame from '../views/GameMain.vue';
Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Main',
        component: MainGame,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
