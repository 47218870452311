<template>
    <div class="v-main">
        <div class="main-container">
            <Television :isLoading="loading">
                <div class="main-screen">
                    <canvas id="gameScreen" :width="SCREEN_WIDTH" :height="SCREEN_HEIGHT"></canvas>
                    <div class="action-demo"></div>
                </div>
            </Television>
            <div class="main-game">
                <!-- <Button @click="openChoose">选择游戏</Button> -->
                <img @click="openChoose" src="../assets/game-machine.png" alt="">
            </div>
            <div class="main-control">
                <div class="left-control">
                    <button class="direction-action up" :data-type="ACTION_TYPE.up">
                        <Icon type="up" />
                    </button>
                    <button class="direction-action down" :data-type="ACTION_TYPE.down">
                        <Icon type="down" />
                    </button>
                    <button class="direction-action left" :data-type="ACTION_TYPE.left">
                        <Icon type="left" />
                    </button>
                    <button class="direction-action right" :data-type="ACTION_TYPE.right">
                        <Icon type="right" />
                    </button>
                </div>
                <div class="center-control">
                    <div class="operation-center select" type="primary" :data-type="ACTION_TYPE.select">SELECT</div>
                    <div class="operation-center start" type="primary" :data-type="ACTION_TYPE.start">START</div>
                </div>
                <div class="right-control">
                    <div class="operation-action a" type="primary" shape="circle" :data-type="ACTION_TYPE.a">A</div>
                    <div class="operation-action b" type="primary" shape="circle" :data-type="ACTION_TYPE.b">B</div>
                </div>
            </div>
        </div>
        <ChooseGame ref="choose" @ensure="ensureChoose" />
    </div>
</template>

<script>
import { Button, Spin, Icon } from 'ant-design-vue';
import ChooseGame from './ChooseGame';
import Television from './Television';
import { initNes, bootNes, getNesData, bindAction, bindVirtualAction } from '../common/nes';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../constants/screen';
import { ACTION_TYPE, ICON_UP, ICON_DOWN, ICON_LEFT, ICON_RIGHT } from '../constants/action';
export default {
    name: 'Main',
    components: {
        Button,
        Spin,
        Icon,
        ChooseGame,
        Television,
    },
    mounted() {
        const ele = document.querySelector('#gameScreen');
        initNes(ele);
        this.bindActionBtn();
        bindAction();
    },
    data() {
        return {
            loading: false,
            SCREEN_WIDTH,
            SCREEN_HEIGHT,
            ICON_UP,
            ICON_DOWN,
            ICON_LEFT,
            ICON_RIGHT,
            ACTION_TYPE,
        };
    },
    methods: {
        openChoose() {
            this.$refs.choose.open();
        },
        async ensureChoose(link) {
            this.loading = true;
            const romData = await getNesData(link);
            if (romData) {
                this.loading = false;
                bootNes(romData);
            }
        },
        bindActionBtn() {
            bindVirtualAction('.direction-action.up', ACTION_TYPE.up);
            bindVirtualAction('.direction-action.down', ACTION_TYPE.down);
            bindVirtualAction('.direction-action.left', ACTION_TYPE.left);
            bindVirtualAction('.direction-action.right', ACTION_TYPE.right);
            bindVirtualAction('.operation-center.select', ACTION_TYPE.select);
            bindVirtualAction('.operation-center.start', ACTION_TYPE.start);
            bindVirtualAction('.operation-action.a', ACTION_TYPE.a);
            bindVirtualAction('.operation-action.b', ACTION_TYPE.b);
        },
    },
};
</script>

<style lang="less">
.v-main {
    background-color: #fff;
    height: 100vh;
    max-width: 400px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    user-select: none;
    .main-container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .main-screen {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #000;
        #gameScreen {
            background-color: #000;
        }
    }
    .main-control {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        margin-bottom: 30px;
        border: 1px solid #ccc;
        box-shadow: 0 1px 2px #fff inset, 0 -1px 0 #a8abae inset;
        border-radius: 10px;
        background-color: #eee;
        width: 365px;
        height: 150px;
        // display: none;
        .left-control {
            position: relative;
            height: 140px;
            width: 140px;
            // background-color: #ccc;
            .direction-action {
                position: absolute;
                height: 50px;
                width: 50px;
                display: block;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #8c96a0;
                text-shadow: 1px 1px 1px #fff;
                border: 1px solid #dce1e6;
                box-shadow: 0 1px 2px #fff inset, 0 -1px 0 #a8abae inset;
                background: -webkit-linear-gradient(top,#f2f3f7,#e4e8ec);
                &.active {
                    box-shadow: 0 1px 3px #a8abae inset, 0 3px 0 #fff;
                    background: -webkit-linear-gradient(top,#e4e8ec,#e4e8ec);
                }
            }
            .up {
                top: 0;
                left: 50%;
                margin-left: -25px;
            }
            .down {
                bottom: 0;
                left: 50%;
                margin-left: -25px;
            }
            .left {
                left: 0;
                top: 50%;
                margin-top: -25px;
            }
            .right {
                right: 0;
                top: 50%;
                margin-top: -25px;
            }
        }
        .center-control {
            height: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 75px;
            .operation-center {
                width: 55px;
                height: 25px;
                margin-bottom: 10px;
                color: #ccc;
                text-align: center;
                border-radius: 4px;
                font-size: 10px;
                line-height: 23px;
                // text-shadow: 1px 1px 1px #fff;
                border: 1px solid #dce1e6;
                box-shadow: 0 1px 2px #8b8b8b inset, 0 -1px 0 #3d3d3d inset, 0 -2px 3px #8b8b8b inset;
                background: -webkit-linear-gradient(top,#656565,#4c4c4c);
                &.active {
                    box-shadow: 0 1px 3px #111 inset, 0 3px 0 #fff;
                    background: -webkit-linear-gradient(top,#424242,#575757);
                }
            }
        }
        .right-control {
            position: relative;
            height: 140px;
            width: 140px;
            // background-color: #ccc;
            .operation-action {
                position: absolute;
                height: 70px;
                width: 70px;
                display: block;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;

                color: #8c96a0;
                text-shadow: 1px 1px 1px #fff;
                border: 1px solid #dce1e6;
                box-shadow: 0 1px 2px #fff inset, 0 -1px 0 #a8abae inset;
                background: -webkit-linear-gradient(top,#f2f3f7,#e4e8ec);
                &.active {
                    box-shadow: 0 1px 3px #a8abae inset, 0 3px 0 #fff;
                    background: -webkit-linear-gradient(top,#e4e8ec,#e4e8ec);
                }
            }
            .a {
                right: 0px;
                top: 5px;
            }
            .b {
                left: 0px;
                bottom: 5px;
            }
        }
    }
    .action-demo {
        width: 0;
        height: 0;
    }
}
</style>
