<template>
    <div class="main">
        <div class="panel">
            <div class="controller-area">
                <div class="controller">
                    <button class="button-up">up</button>
                    <button class="button-right">right</button>
                    <button class="button-down">down</button>
                    <button class="button-left">left</button>
                </div>
                <div class="action-demo"></div>
                <div class="game-info" @click="openChoose">{{gameName || '未选择'}}</div>
            </div>
            <div class="function-area">
                <div class="screen">
                    <Spin :spinning="loading">
                        <canvas id="gameScreen" :width="SCREEN_WIDTH" :height="SCREEN_HEIGHT"></canvas>
                    </Spin>
                </div>
                <div class="function">
                    <button class="button-select">Select</button>
                    <button class="button-start">Pause</button>
                </div>
            </div>
            <div class="action-area">
                <div class="action">
                    <button class="button-b">B</button>
                    <button class="button-a">A</button>
                </div>
            </div>
            <div class="sign">FC GAME</div>
        </div>
        <ChooseGame ref="choose" @ensure="ensureChoose" />
    </div>
</template>

<script>
import { Spin } from 'ant-design-vue';
import ChooseGame from './ChooseGame';
import { initNes, bootNes, getNesData, bindAction, bindVirtualAction } from '../common/nes';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../constants/screen';
import { ACTION_TYPE } from '../constants/action';
import '../styles/main.less';
export default {
    name: 'GameMain', // 游戏主页
    components: {
        Spin,
        ChooseGame,
    },
    mounted() {
        const ele = document.querySelector('#gameScreen');
        initNes(ele);
        this.bindActionBtn();
        bindAction();
    },
    data() {
        return {
            loading: false,
            gameName: '',
            SCREEN_WIDTH,
            SCREEN_HEIGHT,
            ACTION_TYPE,
        };
    },
    methods: {
        openChoose() {
            this.$refs.choose.open();
        },
        async ensureChoose(item) {
            this.loading = true;
            this.gameName = item.name;
            const romData = await getNesData(item.link);
            if (romData) {
                this.loading = false;
                bootNes(romData);
            }
        },
        bindActionBtn() {
            bindVirtualAction('.button-up', ACTION_TYPE.up);
            bindVirtualAction('.button-down', ACTION_TYPE.down);
            bindVirtualAction('.button-left', ACTION_TYPE.left);
            bindVirtualAction('.button-right', ACTION_TYPE.right);
            bindVirtualAction('.button-select', ACTION_TYPE.select);
            bindVirtualAction('.button-start', ACTION_TYPE.start);
            bindVirtualAction('.button-a', ACTION_TYPE.a);
            bindVirtualAction('.button-b', ACTION_TYPE.b);
        },
    },
};
</script>
